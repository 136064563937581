import * as React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';

const QualityLinkBox = ({act}) => {

  return (
    <>
      <div className="link-box">
        <div className="c-content">

          <div className={`link ${act}`}>
            <button
              className="btn"
              onClick={() => scrollTo('#kit')}>
              <p className='txt'>キッチン</p>
            </button>
            <button
              className="btn"
              onClick={() => scrollTo('#laundry')}>
              <p className='txt'>バス・<br className='_sp' />パウダールーム</p>
            </button>
            <button
              className="btn"
              onClick={() => scrollTo('#amenity')}>
              <p className='txt'>アメニティ・収納</p>
            </button>
            <button
              className="btn"
              onClick={() => scrollTo('#quality')}>
              <p className='txt'>クオリティ</p>
            </button>
            <button
              className="btn"
              onClick={() => scrollTo('#strct')}>
              <p className='txt'>構造</p>
            </button>

          </div>
        </div>
      </div>

    </>
  )
}
export default QualityLinkBox