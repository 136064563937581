import * as React from 'react'
import { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper';
import { Navigation } from 'swiper';
import 'swiper/css';
import "swiper/css/thumbs"
import "swiper/css/navigation"
import { AttractivenessMapSlideList } from '@components/index'

const AttractivenessMapSlide = () => {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <div className="map-wrap">

        <div className="map-box">
          <StaticImage
            src="../assets/images/attractiveness/map.png"
            alt=""
          />
          <Swiper
            onSwiper={setThumbsSwiper}
            modules={[Thumbs]}
            slidesPerView={18}
            watchSlidesProgress={true}
            className='plot-box'
          >
            <SwiperSlide className='plot plot-1'>
              <StaticImage src="../assets/images/attractiveness/map_plot_1.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-2'>
              <StaticImage src="../assets/images/attractiveness/map_plot_2.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-3'>
              <StaticImage src="../assets/images/attractiveness/map_plot_3.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-4'>
              <StaticImage src="../assets/images/attractiveness/map_plot_4.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-5'>
              <StaticImage src="../assets/images/attractiveness/map_plot_5.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-6'>
              <StaticImage src="../assets/images/attractiveness/map_plot_6.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-7'>
              <StaticImage src="../assets/images/attractiveness/map_plot_7.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-8'>
              <StaticImage src="../assets/images/attractiveness/map_plot_8.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-9'>
              <StaticImage src="../assets/images/attractiveness/map_plot_9.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-10'>
              <StaticImage src="../assets/images/attractiveness/map_plot_10.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-11'>
              <StaticImage src="../assets/images/attractiveness/map_plot_11.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-12'>
              <StaticImage src="../assets/images/attractiveness/map_plot_12.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-13'>
              <StaticImage src="../assets/images/attractiveness/map_plot_13.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-14'>
              <StaticImage src="../assets/images/attractiveness/map_plot_14.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-15'>
              <StaticImage src="../assets/images/attractiveness/map_plot_15.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-16'>
              <StaticImage src="../assets/images/attractiveness/map_plot_16.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-17'>
              <StaticImage src="../assets/images/attractiveness/map_plot_17.png" alt="" />
            </SwiperSlide>
            <SwiperSlide className='plot plot-18'>
              <StaticImage src="../assets/images/attractiveness/map_plot_18.png" alt="" />
            </SwiperSlide>


          </Swiper>
        </div>

        <div className="data-box">
          <div className="slide-box">

            <Swiper
              modules={[Thumbs, Navigation]}
              navigation
              loop={true}
              slidesPerView={1}
              thumbs={{ swiper: thumbsSwiper }}
            >
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='ラスカ小田原'
                  subttl='約370m／徒歩5分'
                  txt='小田原駅東口にある駅ビル。2階は「ミナカ小田原」の金次郎広場、1階は「HaRuNe小田原」と専用通路で結ばれています。ファッション・カルチャー・グルメ・食料品など多彩なショップが充実しています。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_1.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='トザンイースト'
                  subttl='約270m／徒歩4分'
                  txt='小田原駅東口のロータリー前にある飲食・サービス店舗の入った商業ビル。城下町小田原をオマージュして、伝統工芸である寄木細工や提灯をモチーフとして取り入れたデザインの建物には、カフェやファストフード店などの5つの店舗があります。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_2.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='ドン・キホーテ'
                  subttl='約140m／徒歩2分'
                  txt='総合ディスカウントストア。食料品・酒類・日用雑貨・ペット用品・家電・インテリア・衣類・カー用品・スポーツ用品など、あらゆる生活必需品の豊富な品揃えがこのお店の強み。深夜2時まで営業しています。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_3.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='Odakyu OX'
                  subttl='約220m／徒歩3分'
                  txt='毎日のお買い物は徒歩3分にある豊富な品揃えが人気のスーパー「Odakyu OX」が便利。バラエティ豊かな商品ラインナップには地元の品も多数あります。また、購入した商品を希望の時間帯に届けてくれる“お買い物お届けサービス”もあり。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_4.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='小田原銀座商店会'
                  subttl='約10m／徒歩1分'
                  txt='小田原銀座商店会では毎年小田原の街並みをテーマに作品を募集して開催する、平成15年に始まった展覧会「街展」が人気。画廊がいくつかあり芸術性の高い地域としても知られています。電線・電柱・アーケードのない開放的で町歩きのしやすい通りです。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_5.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='HaRuNe小田原'
                  subttl='約270m／徒歩4分'
                  txt='小田原地下街「HaRuNe小田原」は「ヒト」「モノ」「コト」が集まる新スタイルのショッピング&コミュニティ空間。小田原の農水産物、それらの加工品、木製品、地産地消のメニューを提供するレストランなど、魅力的な地域の資源を扱うお店が集結。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_6.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='小田原駅前商店会'
                  subttl='約200m／徒歩3分'
                  txt='大正9年に小田原駅の開業とともに誕生した駅にいちばん近い歴史ある商店街。お土産となる地場の名産品をはじめ、老舗のお店が軒を連ねています。アーケードには歴代の小田原城主の家紋の装飾が施されています。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_7.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='ミナカ小田原'
                  subttl='約340m／徒歩5分'
                  txt='江戸情緒薫る「小田原新城下町」と「タワー棟」があり、フード・飲食を中心に多様な40以上の店舗が展開。小田原・西湘エリアの地元人気飲食店・図書館・子育て支援センター等の行政施設、箱根湯本の天然温泉が愉しめる駅直結のホテルなどを併設しています。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_8.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='小田原お堀端 万葉の湯'
                  subttl='約160m／徒歩2分'
                  txt='小田原の駅前に立地する24時間営業・年中無休の日帰り温泉施設です。湯河原から上質の天然温泉を毎日運び、高級旅館のような空間でお寛ぎいただけます。露天風呂・大浴場・ロウリュ炭サウナ・ひのき風呂・貸切風呂などがあります。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_9.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='大工町商店会'
                  subttl='約20m／徒歩1分'
                  txt='大工町は後北条時代からあると言われる歴史ある街。もとは大工の棟梁やその下で働く職人たちの長屋が多くあったと考えられています。現在ではゆったりと歩ける広い舗道が整備され、さまざまなお店が建ち並ぶ商店街として賑わっています。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_10.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='小田原城'
                  subttl='約390m／徒歩5分'
                  txt='戦国時代、日本最大の城郭を誇っていた小田原城。現在は本丸・二の丸の大部分と総構の一部が国の史跡に指定されています。四季折々の豊かな自然を擁する城址公園内には、小田原城歴史見聞館・郷土文化館・こども遊園地などがあります。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_11.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='お堀端商店会'
                  subttl='約140m／徒歩2分'
                  txt='小田原駅前通りから小田原城のお堀に沿って約500mつづくお堀通り沿いの商店街。さまざまな個性あふれる店舗が軒を連ね、地元の人々も観光客も買い物がしたくなる、伝統と新しさが融合した活気あふれるショッピングストリートです。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_12.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='緑一番街商店会'
                  subttl='約10m／徒歩1分'
                  txt='昭和30年頃に始まったこの商店街のある栄町は、以前は「緑町」と呼ばれており、その名残で「緑一番街」と名付けられました。全国的にも有名な滑らない“ギョサン”（ビーチサンダル）はこの商店会のマツシタ靴店がブーム発祥のお店です。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_13.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='小田原城址公園こども遊園地'
                  subttl='約950m／徒歩12分'
                  txt='昭和25年、小田原こども文化博覧会時に開園した小田原城址公園内にある遊園地です。豆汽車やバッテリーカー などの遊具があり、小さいお子さまに人気の施設です。 年間を通してさまざまなイベントが開催されています。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_14.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='郷土文化館'
                  subttl='約750m／徒歩10分'
                  txt='郷土の歴史と自然、文化を継承する施設として資料を収集・保管・保存し、展示公開や研究調査、普及活動を行っています。館内では歴史資料室・文化人資料室・考古資料室・民俗資料施設・自然科学資料室にて展示品を見学できます。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_15.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='小田原三の丸ホール'
                  subttl='約570m／徒歩8分'
                  txt='大ホール・小ホール・展示室・ギャラリー回廊・スタジオ・練習室などからなる、かつての小田原市民会館の歴史を受け継ぐ新しい文化・芸術拠点です。敷地内には小田原市観光交流センターがあり、観光情報や小田原にまつわる商品などが並べられています。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_16.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='三の丸小学校'
                  subttl='約800m／徒歩10分'
                  txt='三の丸小学校は1822年に小田原城三の丸に開設された小田原藩校「集成館」を始まりとする長い歴史を持つ小学校。児童数は年々増加傾向にあり、2020年度の児童数は豊川小学校に次いで第2位。体育館の地下にプールがあります。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_17.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>
              <SwiperSlide>
                <AttractivenessMapSlideList
                  ttl='御幸の浜'
                  subttl='約790m／徒歩10分'
                  txt='1873年に明治天皇と皇后が地引き網をご覧になって以来「御幸の浜」と呼ばれるようになりました。景色が良く伊豆半島や三浦・房総半島も見渡せ、海辺の散策や海水浴などが楽しめます。元旦は日の出とともに海の安全と健康を祈願する初泳ぎが行われます。'>
                  <StaticImage
                    src="../assets/images/attractiveness/map_img_18.jpg"
                    alt=""
                  />
                </AttractivenessMapSlideList>
              </SwiperSlide>




            </Swiper>

          </div>
        </div>

      </div>
    </>
  )
}
export default AttractivenessMapSlide