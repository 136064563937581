import * as React from 'react'
// import { useEffect } from 'react';
// import { useStateIfMounted } from "use-state-if-mounted";
import { StaticImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay,EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
// import MainLogo from '@svgs/main_logo.svg'
// import MainTxt1 from '@svgs/main_txt_1.svg'
// import MainTxt2 from '@svgs/main_txt_2.svg'
import List5 from '@svgs/list_5.svg'

const TopMain = () => {

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  // const [act, setAct] = useStateIfMounted('')
  // const actChange = (num) => {
  //   if (num === 1) {
  //     setAct('act-1')
  //   }
  //   if (num === 2) {
  //     setAct('act-2')
  //   }
  //   if (num === 3) {
  //     setAct('act-3')
  //   }
  // }

  // useEffect(() => {

  //   setTimeout(actChange, 1000, 1);
  //   setTimeout(actChange, 2000, 2);
  //   setTimeout(actChange, 3000, 3);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <div className={`top-main-wrap `}>

        <div className="main-box">
          <div className="main-img">
            <Swiper
              modules={[Pagination,Autoplay,EffectFade]}
              loop={true}
              slidesPerView={1}
              speed={1000}
              allowTouchMove={false}
              effect={"fade"}
              pagination={pagination}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              className='main-box'
            >
              <SwiperSlide>
                <StaticImage
                  src="../assets/images/top/main-slide-1.jpg"
                  alt=""
                  className='_pc'
                />
                <StaticImage
                  src="../assets/images/top/main-slide-1_sp.jpg"
                  alt=""
                  className='_sp'
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../assets/images/top/main-slide-2.jpg"
                  alt=""
                  className='_pc'
                />
                <StaticImage
                  src="../assets/images/top/main-slide-2_sp.jpg"
                  alt=""
                  className='_sp'
                />
              </SwiperSlide>
            </Swiper>
          </div>

          {/* <div className="logo"><MainLogo /></div> */}

          {/* <div className="txt-1"><MainTxt1 /></div> */}
          {/* <div className="txt-2"><MainTxt2 /></div> */}
        </div>

        {/* <div className="merit-box">
          <div className="flex">
            <p className="list list-1">
              <span className='span-1'>全邸</span><br className='_pc' />
              <span className='span-2'>南向き</span>
            </p>
            <p className="list list-2">
              <span className='span-1'>商業・行政・緑が集積する</span><br />
              <span className='span-2'>東口エリア</span>
            </p>
            <p className="list list-3">
              <span className='span-2'>相模湾</span>
              <span className='span-1'>を<br className='_pc' />望む</span><br className='_pc' />
              <span className='span-3'>※一部住戸を除く</span>
            </p>
            <p className="list list-4">
              <span className='span-1'>地上</span><br className='_pc' />
              <span className='span-2'>14階建て</span>
            </p>
            <p className="list list-5">
              <span className="list-5-img">
                <List5 />
              </span>
            </p>

            <p className="caption">紋デザイン / 京源三代目 紋章上繪師 波戸場承龍</p>
          </div>

        </div> */}

      </div>
    </>
  )
}
export default TopMain