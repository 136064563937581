import * as React from 'react'

const AttractivenessMapSlideList = ({ ttl, subttl, txt, children }) => {

  return (
    <div className="list">
      <div className="img-box">
        <div className="img">
          {children}
        </div>
        <p className="ttl">{ttl}</p>
        <p className="subttl">（{subttl}）</p>
      </div>
      <div className="txt-box">
        <p className="txt">{txt}</p>
      </div>
    </div>

  )
}
export default AttractivenessMapSlideList