import * as React from 'react'

const ComMain = ({ ttl, subttl, caption,caption2, children }) => {

  return (
    <>
      <div className='com-main'>
        {children}
        <h1>{ttl}<br /><span>{subttl}</span></h1>
        <p className="c-cap">{caption}<br className='_sp' />{caption2}</p>
      </div>

    </>
  )
}
export default ComMain