import * as React from 'react'
import { useState, useEffect } from 'react';
import { useStateIfMounted } from "use-state-if-mounted";
import scrollTo from 'gatsby-plugin-smoothscroll';
import Logo from '@svgs/logo_odawara_white.svg'
import Close from '@svgs/icon_close.svg'

const AttractivenessLink = () => {

  const [open, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!open)
  }

  const [navNum, setNavNum] = useStateIfMounted('')
  const numChange = (num) => {
    if (num === 1) {
      setNavNum('num1')
    }
    if (num === 2) {
      setNavNum('num2')
    }
    if (num === 3) {
      setNavNum('num3')
    }
    if (num === 4) {
      setNavNum('num4')
    }
    if (num === 5) {
      setNavNum('num5')
    }
  }

  useEffect(() => {

    console.log("mount");
    const secPoint = document.querySelectorAll('.sec-point');
    let headerH = document.querySelector('header').clientHeight;
    let navH = document.querySelector('nav').clientHeight;
    let headerNavH = headerH + navH;

    window.addEventListener('scroll', () => {
      let scroll = window.pageYOffset;
      let secPointTop1 = secPoint[0].getBoundingClientRect().top + scroll;
      let secPointTop2 = secPoint[1].getBoundingClientRect().top + scroll;
      let secPointTop3 = secPoint[2].getBoundingClientRect().top + scroll;
      let secPointTop4 = secPoint[3].getBoundingClientRect().top + scroll;
      let secPointTop5 = secPoint[4].getBoundingClientRect().top + scroll;

      if (scroll > (secPointTop5 - headerNavH)) {
        numChange(5)
      } else if (scroll > (secPointTop4 - headerNavH)) {
        numChange(4)
      } else if (scroll > (secPointTop3 - headerNavH)) {
        numChange(3)
      } else if (scroll > (secPointTop2 - headerNavH)) {
        numChange(2)
      } else if (scroll > (secPointTop1 - headerNavH)) {
        numChange(1)
      } else {
        numChange(0)
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <button
        className={`${open ? 'act' : ''} anker-btn _sp`}
        onClick={toggle}
      >
        <div className="img">
          {open ? <Close /> : <Logo />}
        </div>
        <p className='txt'>
          {`${open ? 'CLOSE' : 'CLICK'}`}
        </p>
      </button>

      <div className={`${open ? 'act' : ''} anker-wrap`}>
        <div className={`anker-box ${navNum}`}>
          <button
            className="list list1"
            onClick={() => scrollTo('#secPoint1')}
          >
            <div>
              <p className="p1">POINT</p>
              <p className="p2">1</p>
              <p className="p3">遙かなる歴史を<br />今に受け継ぐ</p>
            </div>
          </button>
          <button
            className="list list2"
            onClick={() => scrollTo('#secPoint2')}
          >
            <div>
              <p className="p1">POINT</p>
              <p className="p2">2</p>
              <p className="p3">進化の歩みを<br />止めない小田原</p>
            </div>
          </button>
          <button
            className="list list3"
            onClick={() => scrollTo('#secPoint3')}
          >
            <div>
              <p className="p1">POINT</p>
              <p className="p2">3</p>
              <p className="p3">新旧の<br />彩りある施設が<br />多彩に集積</p>
            </div>
          </button>
          <button
            className="list list4"
            onClick={() => scrollTo('#secPoint4')}
          >
            <div>
              <p className="p1">POINT</p>
              <p className="p2">4</p>
              <p className="p3">自然の豊かさに<br />恵まれた地</p>
            </div>
          </button>
          <button
            className="list list5"
            onClick={() => scrollTo('#secPoint5')}
          >
            <div>
              <p className="p1">POINT</p>
              <p className="p2">5</p>
              <p className="p3">子育てしやすく<br />暮らしやすい東口</p>
            </div>
          </button>

        </div>

      </div>
    </>
  )
}
export default AttractivenessLink